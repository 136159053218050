// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$color1: rgb(19, 237, 154);
$color2: rgb(113, 247, 159);
$color3: rgb(61, 214, 208);
$color4: rgb(251, 205, 77);
$color5: rgb(255, 255, 255);
$color6: rgb(41, 45, 57);