// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables.scss';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

body{
    text-align: justify;
    line-height: 2em;
    text-justify: inter-word;
    transition: 0.5s;
    background-color: #FFFFFF;
    color: #333333;
    *{font-family: 'Roboto', sans-serif;}
}

// blockquote{
//     border-left: 1px solid #232427;
// }
.menu{
    background-color: $color6;
    padding: 0px;
    z-index: 10;
    nav{
        padding: 0px;
        .navbar-light{color: $color1;}

        div.navbar-collapse > ul > li{
            a{
                background-color: $color6;
                padding-top: 16px;
                padding-bottom: 16px;
                @include media-breakpoint-down(md){
                    padding-left: 35px;
                }

                &.active{
                    color: lighten($color1, 10%) !important;
                    background-color: lighten($color6, 10%) !important;
                }

                &:hover{
                    color: darken($color1, 10%) !important;
                    background-color: darken($color6, 2%) !important;
                }
            }
        }
    }

    *{
        color: $color1 !important;
        font-weight: 600;
    }

    button.navbar-toggler-right{
        margin-top: 16px;
        margin-bottom: 16px;
        right: 0px;
    }

    a.navbar-brand{
        margin-left: 35px;
        padding-top: 16px;
        padding-bottom: 16px;

        &:hover{color: darken($color1, 10%);}
    }
}

#app{
    font-family: Popins;

    h1, h2{
        text-align: left;
        font-weight: 700;
        letter-spacing: -.02em;
        word-spacing: 0.1em;
        line-height: 1.2em;
        color: #333333;
    }

    #header{
        background-color: darken(white, 5%);
        padding: 0px;

        .header{
            min-height: 70vh;
            background-color: $color3;
            position: relative;

            .image{
                position: relative;
                width: 100%;

                &>div{
                    min-height: 70vh;
                    width: 100%;
                    background-image: url('/images/preview.jpg');
                    background-blend-mode: multiply;
                    opacity : 0.1;
                }
            }

            .content{
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;

                h1{
                    opacity: 1;
                    z-index: 6;
                    font-weight: 700;
                    letter-spacing: -.02em;
                    word-spacing: 0.1em;
                    line-height: 1.2em;
                    font-size: 7vw;
                    color: $color6;
                    text-decoration: underline;
                    text-decoration-color: $color4;
                }
            }
        }

        #featured{
            padding-top: 50px;
            padding-bottom: 50px;
        }

        #download{
            padding-top: 50px;
            padding-bottom: 50px;
            background-color: $color2;

            .card{
                padding: 20px;
                a{
                    width: 100%;
                    padding: 10px;
                }
            }
        }

        #social-media{
            background-color: $color6;

            .row{
                .media-link{
                    height: 8vh;
                    display: table;

                    a{
                        padding: 15px;
                        display: table-cell;
                        vertical-align: middle;
                        color: $color1;
                        font-size: 1.4em;
                        font-weight: 600;
                        text-align: center;
                    }
                }
            }
        }
    }
    //in about section
    .people{
        .text{
            flex: 1;

            @include media-breakpoint-down(md){
                width: 100%;
            }
        }

        .image{
            flex-basis: 20%;
            text-align: center;
            @include media-breakpoint-down(md){
                flex-basis: 100%;
            }
        }

        img{
            max-height: 15rem;
            width: auto;
        }
    }


    .text-block{
        padding-top: 2.5em;

        h2{padding-bottom: 0.5em;}
        img.image-style{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 75%;
            border-radius: 0.5rem;
            box-shadow: 0 5px 10px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.24);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            transform: scale(1.0);

            &:hover{
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                transform: scale(1.01);
            }
        }
    }

    #personen{
        div{
            .card{height:100%;}
        }
    }

    #event_select + div.dropdown-menu{
        left: auto;
        width: calc(100% - 30px);
        text-align: center;
    }
}

.cookie{
    background-color: black;
    color: white;
    padding: 1em 2em;
    p{
        margin: 0px;
        display: inline-block;
    }
    button{
        background-color: white;
        color: black;
    }
}